import React from 'react';

import { DefaultLayout } from '../layouts/DefaultLayout';
import SEO from '../components/seo';
import { Box, Button, Flex, Reveal, Typography } from '../ui';
import { BinaryCanvas } from '../components/BinaryCanvas/BinaryCanvas';
import { ArrowLeftIcon } from 'xyz-icon-set-react';

const NotFoundPage: React.FC<React.PropsWithChildren<unknown>> = props => (
  <DefaultLayout {...props} breadcrumb={[]}>
    <SEO title="404: Not found" />

    <BinaryCanvas
      style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
      }}
    />

    <Box marginTop={240} maxWidth={400} marginLeft="auto" marginRight="auto">
      <Reveal inactiveOpacity={0.6} activeOpacity={0.75}>
        <Typography.Headline level={2} textAlign="center">
          404 - Not Found
        </Typography.Headline>
      </Reveal>

      <Typography.Paragraph textAlign="center">
        Something broke and now zeros and ones are everywhere!
      </Typography.Paragraph>

      <Box marginTop={40}>
        <Flex justifyContent="center">
          <Typography.Link href="/" textDecoration="none" textAlign="center">
            <Button variant="hollow" size="S" leadingIcon={<ArrowLeftIcon />}>
              Go To The Homepage
            </Button>
          </Typography.Link>
        </Flex>
      </Box>
    </Box>
  </DefaultLayout>
);

export default NotFoundPage;
